
import api from "api"
import useSWR from "swr"

/**
 * idをもとにArtWorkをお気に入り登録されてるか取得するSWR
 */
export const useIsFollowing = (id: string) => {
  return useSWR<boolean>(id ? `/art_works/${id}/follow_status` : null, (key) => api.get(key).then(res => res.data), {
    revalidateOnFocus: false
  })
}
