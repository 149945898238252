import { numberWithDelimiter } from "../../helpers/numberWithDelimiter"

const prefix_mapping =
  {
    "jpy": "JPY",
    "usd": "USD",
  }

const suffix_mapping =
  {
    "jpy": "円",
    "usd": "ドル(USD)",
  }

type Args = {
  currency: string,
  publish_price?: number | null,
  is_available_online?: boolean,
  prefix?: boolean,
  quantity?:number,
}

/**
 * Generate price text
 * @param {Args} args
 * @returns string
 */
export const generatePriceText = ({ currency, publish_price, is_available_online, prefix = true, quantity = 1 }:Args): string => {
  const currency_prefix = prefix_mapping[currency as keyof typeof prefix_mapping] || currency
  const currency_suffix = suffix_mapping[currency as keyof typeof prefix_mapping]

  if (typeof publish_price === "number" && is_available_online) {
    if (prefix) {
      return `${currency_prefix} ${numberWithDelimiter(publish_price * quantity)}`
    } else {
      return `${numberWithDelimiter(publish_price * quantity)}${currency_suffix}`
    }
  } else {
    return ""
  }
}
