import { message } from "antd"
import api from "api"
import { Button } from "components/shared/Button"
import { useAuth } from "contexts/auth"
import colors from "helpers/colors"
import { useIsFollowing } from "hooks/artworks"
import { useTranslation } from "next-i18next"
import HeartIcon from "public/icons/heart.svg"
import { useState, VFC } from "react"
import styled from "styled-components"

type Props = {
  artWorkId: string
  size?: "sm" | "md" | "lg"
  withUnFollow?: boolean
}

export const FollowButtonM: VFC<Props> = ({ artWorkId, size = "sm", withUnFollow = false }) => {
  const { isAuthenticated, setIsSignInOpened, setSignInNotification } = useAuth()
  const { t } = useTranslation()
  const { data: isFollow, mutate } = useIsFollowing(artWorkId)
  const [hover, setHover] = useState(false)

  const onClickFollowButton = async () => {
    if (!isAuthenticated) {
      setIsSignInOpened(true)
      setSignInNotification(t("お気に入りを利用するには、ログインが必要です。"))
      return
    }

    if (isFollow) {
      const { data, error }: { data: any; error: any } = await api.delete(`/art_works/${artWorkId}/unfollow`)
      if (data) {
        message.success(t("お気に入りを解除しました"))
        mutate()
      }
      if (error) {
        message.error(error.data.error)
      }
    } else {
      const { data, error }: { data: any; error: any } = await api.post(`/art_works/${artWorkId}/follow`)
      if (data) {
        message.success(t("お気に入りに追加しました"))
        mutate()
      }
      if (error) {
        message.error(error.data.error)
      }
    }
  }

  if (!isFollow) {
    return (
      <StyledButton
        type="secondary"
        size={size}
        style={{ fontSize: "12px", fontWeight: 700 }}
        onClick={(e) => {
          e.preventDefault()
          onClickFollowButton()
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <HeartIcon style={{
          width: "12px",
          height: "12px",
          marginRight: "4px",
          color: hover ? colors.white : colors.black,
          opacity: hover ? "100%" : "60%"
        }} />
        {t("お気に入り")}
      </StyledButton>
    )
  }

  if (!withUnFollow) {
    return (
      <StyledButton
        size={size}
        style={{ fontSize: "12px", fontWeight: 700 }}
        disabled
      >
        <HeartIcon style={{ width: "12px", height: "12px", marginRight: "4px", color: "white" }} />
        {t("お気に入り")}
      </StyledButton>
    )
  }

  return (
    <StyledButton
      type="primary"
      size={size}
      style={{ fontSize: "12px", fontWeight: 700 }}
      onClick={(e) => {
        e.preventDefault()
        onClickFollowButton()
      }}
    >
      <HeartIcon style={{ width: "12px", height: "12px", marginRight: "4px" }} />
      {t("お気に入りを解除する")}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
