import api from "api"
import useSWR from "swr"

type ArtWorkType = ArtWork & {
  art_work_images: ArtWorkImage[]
  owner: Owner
  classification: Classification
  techniques: Technique[]
}

/**
 * 作品を取得するSWR
 */
export const useArtwork = (artWorkId?:string, fallbackData?: ArtWorkType) => {
  return useSWR<ArtWorkType>(artWorkId ? `/art_works/${artWorkId}` : null, (key) => api.get(key).then(res => res.data), {
    revalidateOnFocus: false,
    fallbackData
  })
}
