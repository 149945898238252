import { message } from "antd"
import api from "api"
import { useAuth } from "contexts/auth"
import colors from "helpers/colors"
import { useTranslation } from "next-i18next"
import HeartIcon from "public/icons/heart.svg"
import { CSSProperties, useState, VFC } from "react"

type Props = {
  style?: CSSProperties
  artWork: ArtWorkCardType
}

export const FollowButtonS: VFC<Props> = ({ artWork, style }) => {
  const {
    id,
    following_status,
  } = artWork
  const { isAuthenticated, setIsSignInOpened, setSignInNotification } = useAuth()
  const { t } = useTranslation()
  const [isFollow, setIsFollow] = useState(following_status)

  const onClickFollowButton = async () => {
    if (!isAuthenticated) {
      setIsSignInOpened(true)
      setSignInNotification(t("お気に入りを利用するには、ログインが必要です。"))
      return
    }

    if (isFollow) {
      const { data, error }: { data: any; error: any } = await api.delete(`/art_works/${id}/unfollow`)
      if (data) {
        message.success(t("お気に入りを解除しました"))
        setIsFollow(false)
      }
      if (error) {
        message.error(error.data.error)
      }
    } else {
      const { data, error }: { data: any; error: any } = await api.post(`/art_works/${id}/follow`)
      if (data) {
        message.success(t("お気に入りに追加しました"))
        setIsFollow(true)
      }
      if (error) {
        message.error(error.data.error)
      }
    }
  }

  return (
    <HeartIcon
      style={{
        ...style,
        color: isFollow ? colors.blue : colors.black,
        opacity: isFollow ? 1 : 0.6,
        width: "24px",
        height: "24px",
        cursor: "pointer"
      }}
      onClick={(e) => {
        e.preventDefault()
        onClickFollowButton()
      }}
    />
  )
}
