// NOTE: https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
const numberFormat = new Intl.NumberFormat()

/**
 * 3桁ごとにコンマを挿入する関数
 * @param {number} num 挿入元の金額
 */
export const numberWithDelimiter = (num:number) => {
  return numberFormat.format(num)
}
