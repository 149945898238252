import api from "api"
import useSWR from "swr"

/**
 * Homeに表示する作品を取得するSWR
 */
export const useHomeArtworks = () => {
  return useSWR<ArtWorkCardType[]>("/home/art_works?limit=10", (key) => api.get(key).then(res => res.data), {
    revalidateOnFocus: false
  })
}
