import api from "api"
import useSWRInfinite from "swr/infinite"

type ArtWorkType = ArtWork & {
  art_work_images: ArtWorkImage[]
  owner: Owner
  classification: Classification
  techniques: Technique[]
  main_image_thumb_url: string
}

type SearchParamsType = {
  artists_id_in?: string[]
  art_work_title_or_art_work_owner_name_or_art_work_artists_name_or_art_work_artists_name_en_cont_any?: string
}

type SortParamsType = {
  sort_field?: string[]
  sort_direction?: string
}

/**
 * 作品を取得するSWR
 * @params {SearchParamsType} search_params 検索 option
 * @params {number} limit 取得数
 */

export const useArtworks = (search_params: SearchParamsType, limit: number, sort_params?: SortParamsType) => {
  const getKey = (pageIndex: number, previousPageData: { artWorksData: ArtWorkType[] }) => {
    if (previousPageData && !previousPageData.artWorksData.length) return null
    return { path: "/art_works", search_params: search_params, page: pageIndex + 1 }
  }

  const fetcher = ({ path, search_params, page }:{ path:string, search_params:SearchParamsType, page:number}) =>
    api.get<ArtWorkType[]>(path, {
      params: {
        search_params,
        page,
        limit,
        ...sort_params
      }
    }).then(res => {
      if (res.status === 200) {
        return {
          artWorksData: res.data,
          xTotalCount: res.headers["x-total-count"]
        }
      }
      throw res
    }).catch(err => { throw err })

  return useSWRInfinite(
    getKey, fetcher, {
      initialSize: 1,
      revalidateOnFocus: false
    }
  )
}
